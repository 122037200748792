import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/solid";
import React from "react";
import { useEffect, useState } from "react";
import { BasicPortalPageProps } from "../../components/custom-models";
import { callGetApi, LoadingAnimation, PageState, PageStatus } from "../../stdlib/stdlib-form";
import { ListContainer } from "../../stdlib/stdlib-models";
import { TitleLabelClass } from "../../stdlib/stdlib-styles";
import { PanelBackgroundStyle, PanelHeader, RenderHeaderButtons } from "../../stdlib/stdlib-ui";
import { GridCell, GridTable, GridTableProps } from "../../stdlib/stdlib-gridtable";
import { ActionItem } from "../../stdlib/stdlib-action";
import { BookingCommentsModal } from "../../components/BookingCommentsModal";

export const DateToHtmlString: (date: Date) => string = date => {

    const month = date ? date.getMonth() + 1 : 0;
    const day = date ? date.getDate() : 0;

    return `${date?.getFullYear()}-${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""}${day}`;
}

export const DateFromHtmlString: (strValue: string) => Date = strValue => {
    const defaultDate = new Date();
    const pattern = /^(?<year>\d{4})-(?<month>\d{2})-(?<day>\d{2})$/;
    const match = strValue.match(pattern);
    if (!match?.groups) return defaultDate;
    const year: number = Number.parseInt(match.groups.year);
    const month: number = Number.parseInt(match.groups.month);
    const day: number = Number.parseInt(match.groups.day);
    return new Date(year, month - 1, day);
}



interface DailyFastTrackReportItemBase {
    mainPaxName: string;
    email: string;
    phone: string;
    bookedBy: string;
    adults: number;
    highlight: boolean;
    infants: number;
    flight: string;
    service: string;
    comments: string;
}

export interface ArrivalDailyFastTrackReportItem extends DailyFastTrackReportItemBase {
    time: string;
}


export interface DepartureDailyFastTrackReportItem extends DailyFastTrackReportItemBase {
    expectedArrivalTime: string;
    flightDepartTime: string;
}


export interface DailyWaitTimeReportItem {
    avgPointMinutes: number;
}

type CommentsObj = { bookingGuids: string, comments: string };

export const highlightRowClass="bg-blue-700 text-white";


export interface DailyReportTablePageConfig<T extends object, TRouteProps> {
    title: string,
    apiUrl: (props: { date: string } & TRouteProps) => string,
    csvUrl: ((props: { date: string } & TRouteProps) => string) | undefined,
    tableColumns: GridCell<T>[],
    tableStyle: React.CSSProperties
    highlightRow: (row: T) => boolean
    highlightRowClass: string
    appendComments?: boolean;
}


export const DailyReportTablePage = <TModel extends object | CommentsObj, TRouteProps = {},>(config:
    DailyReportTablePageConfig<TModel, TRouteProps>) => {
    const Page = (props: BasicPortalPageProps & TRouteProps) => {
        const nowDate = new Date();
        const [date, setDate] = useState<Date>(nowDate);
        const [_, setState] = useState<PageState>(PageState.NotLoaded);
        const [status, setStatus] = useState<PageStatus>(PageStatus.Idle);
        const [data, setData] = useState<ListContainer<TModel>>();
        const [reloadCount, setReloadCount] = useState<number>(0);

        const [commentsModalCount, setCommentsModalCount] = useState(0);
        const [commentsBookingGuid, setCommentsBookingGuid] = useState<string>();


        const sd = props.setPageDetails;
        useEffect(() => sd({ title: "Daily Report" }), [])

        const columns = [...config.tableColumns];

        if (config.appendComments) {

            columns.push({
                title: "Comments",
                property: "comments",
                cell: (s: CommentsObj) => <button className="link"
                    onClick={() => setCommentsBookingGuid(s.bookingGuids)}>{s.comments}</button>
            } as any);

        }


        const DailyReportHeader: (props: {
            loading: boolean,
            modalOpen: boolean,
            date: string,
            newDate: (date: Date) => void,
            //previousAction: () => void,
            nowAction: () => void,
            refreshAction: () => void,
            nowEnabled: boolean
        }) => JSX.Element = (props) => {


            const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value === props.date) return;
                props.newDate(DateFromHtmlString(e.target.value));
            }

            const headerButtons: ActionItem[] = [{
                kind: "button",
                title: "Today",
                action: props.nowAction,
                disabled: props.modalOpen || props.nowEnabled
            },
            {
                kind: "button",
                title: "Refresh",
                action: props.refreshAction,
                disabled: props.modalOpen
            },
            {
                kind: "gap",
                gap: 2
            },
            ];

            if (config.csvUrl) {
                headerButtons.push({
                    kind: "href",
                    download: true,
                    href: config.csvUrl({ ...props as TRouteProps, date: DateToHtmlString(date) }),
                    target: "_blank",
                    title: "Export",
                });


            }



            return <div className="flex-0 flex flex-row flex-wrap items-center justify-center md:justify-between gap-4 p-4">
                <div className="text-xl flex flex-col align-items-end">
                    <span className={TitleLabelClass}>{config.title}</span>
                    <input className="stdlib-textbox text-sm p-0" type="date" value={props.date} onChange={onChange} />
                    {props.loading && <LoadingAnimation />}
                </div>

                <div className="flex flex-wrap gap-1 bg-white rounded p-2">
                    <RenderHeaderButtons buttons={headerButtons} />
                </div>
            </div>;

        }

        const onCommentsClose = (refreshNeeded: boolean) => {
            setCommentsModalCount(commentsModalCount + 1);
            setCommentsBookingGuid(undefined);
            if (refreshNeeded) {
                setReloadCount(reloadCount + 1);
            }
        }

        const tableProps: GridTableProps<TModel> = {

            tableColumns: config.tableColumns,
            tableStyle: config.tableStyle,
            highlightRow: config.highlightRow,
            highlightRowClass: config.highlightRowClass,
        }


        const dateStr = DateToHtmlString(date);

        useEffect(() => callGetApi<ListContainer<TModel>>(config.apiUrl({ ...props as TRouteProps, date: dateStr }), setState, setStatus, (data) => {
            //console.log("set data");
            //console.log(data);
            setData(data);
        }), [date, reloadCount]);

        //console.log(tableInstance.rows.length);

        const nowEnabled = (nowDate.getMonth() === date.getMonth() && nowDate.getFullYear() === date.getFullYear()) && nowDate.getDate() === date.getDate();

        const modalOpen = false;
        return <div className="flex-1 flex overflow-hidden">
            <div className={`${PanelBackgroundStyle} flex flex-col overflow-hidden  ${modalOpen ? 'calendar-details-open' : 'calendar-details-closed'}`}>
                {DailyReportHeader({
                    //title: config.title,
                    loading: status === PageStatus.Network,
                    date: dateStr,
                    newDate: e => setDate(new Date(e)),
                    //nextAction: () => setStartDate(addDays(startDate, 7)),
                    //previousAction: () => setStartDate(addDays(startDate, -7)),
                    nowAction: () => setDate(nowDate),
                    //newBookingAction: () => setNewOpen(true),
                    refreshAction: () => setReloadCount(reloadCount + 1),
                    nowEnabled,
                    modalOpen
                })}
                <BookingCommentsModal
                    bookingGuid={commentsBookingGuid}
                    openCount={commentsModalCount}
                    onClose={onCommentsClose} />
                <div className="flex flex-grow flex-col min-h-0">
                    <GridTable {...tableProps} data={data?.items} tableColumns={columns} />


                </div>
            </div>

        </div>;
    };

    return Page;

} 